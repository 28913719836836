import React from 'react';
import { graphql } from 'gatsby';
import { kebabCase } from 'lodash';
import { BlogPost } from '../types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BlogPostHeader from '../components/blog-post/Header';
import BlogPostBody from '../components/blog-post/Body';
import BlogPostFooter from '../components/blog-post/Footer';
import BlogPostGrid from '../components/BlogPostGrid';
import 'prismjs/themes/prism.css';
import { mapEdgesToNodes } from '../utils';
import BlogPostRelatedPosts from '../components/blog-post/RelatedPosts';

type BlogPostProps = {
  data: {
    site: any;
    post: BlogPost;
    relatedPosts: {
      edges: {
        node: BlogPost;
      }[]
    }
  }
};

const MdxBlogPost = ({ data }: BlogPostProps) => {
  const { site, post, relatedPosts } = data;
  const { author } = post.frontmatter;

  const posts = mapEdgesToNodes(relatedPosts.edges);

  const image = post.frontmatter.mainImage
    ? post.frontmatter.mainImage.childImageSharp.resize
    : null;
  const imageUrl = image?.src
    ? `${site.siteMetadata.siteUrl}${image.src}`
    : null;

  return (
    <Layout pageTitle="test">
      <SEO
        title={post.frontmatter.title}
        image={image}
        author={author.name}
        description={post.excerpt}
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "headline": "${post.frontmatter.title}",
              "image": [
                "${imageUrl}"
              ],
              "datePublished": "${post.frontmatter.publishedDate}",
              "dateModified": "${post.frontmatter.publishedDate}",
              "author": [{
                "@type": "Person",
                "name": "${author.name}",
                "url": "${site.siteMetadata.siteUrl}/author/${kebabCase(author.name)}"
              }]
            }
          `}
        </script>
      </SEO>

      <article className="blog-post">
        <BlogPostHeader frontmatter={post.frontmatter} />

        <BlogPostBody body={post.body} tableOfContents={post.tableOfContents} />

        <BlogPostFooter author={author} />

        <BlogPostRelatedPosts posts={posts} />
      </article>
    </Layout>
  );
};

export const query = graphql`
  query MdxBlogPostQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    post: mdx(slug: {eq: $slug}) {
      frontmatter {
        title
        author {
          name
          image {
            childImageSharp {
              gatsbyImageData(
                width: 48
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          bio
        }
        categories
        mainImage {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
            gatsbyImageData(
              width: 856
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        publishedDate(formatString: "D MMM YYYY HH:ss")
      }
      tableOfContents
      excerpt(pruneLength: 160)
      body
    }
    relatedPosts: allMdx(limit: 3) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }
  }
`;

export default MdxBlogPost;
