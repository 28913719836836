import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import mediumZoom from 'medium-zoom';
import { TableOfContentItem } from '../../types';
import BlogPostTableOfContents from './TableOfContents';

type BlogPostBodyProps = {
  body: string;
  tableOfContents: {
    items: TableOfContentItem[];
  };
}

const BlogPostBody = ({ body, tableOfContents }: BlogPostBodyProps) => {
  useEffect(() => {
    mediumZoom('.blog-post__body img');
  }, []);

  return (
    <main className="blog-post__body">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <MDXRenderer>
              {body}
            </MDXRenderer>
          </Col>

          <Col md={2} className="d-none d-md-block">
            {tableOfContents.items && <BlogPostTableOfContents items={tableOfContents.items} />}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default BlogPostBody;
