import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import kebabCase from 'lodash/kebabCase';
import { BlogPostMeta } from '../../types';
import AuthorImage from './AuthorImage';
import Breadcrumbs from '../Breadcrumbs';

const BlogPostHeader = ({
  frontmatter: {
    title, publishedDate, categories, author,
  },
}: BlogPostMeta) => (
  <header>
    <Container>
      <Row className="justify-content-md-center mb-4">
        <Col md={10}>
          <Breadcrumbs archiveLabel={categories[0]} archiveUrl={`/categories/${kebabCase(categories[0])}`} />
          <h1 className="font-heading fw-bold">
            {title}
          </h1>

          {author && <div className="blog-post__meta">
            <Link to={`/author/${kebabCase(author.name)}`}>
              <AuthorImage author={author} />
            </Link>
            <div>
              <span className="d-block">Written by {author.name}</span>
              <span>Published on {publishedDate}</span>
            </div>
          </div>}
        </Col>
      </Row>
    </Container>
  </header>
);

export default BlogPostHeader;
