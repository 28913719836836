import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Author } from '../../types';
import AuthorImage from './AuthorImage';

type BlogPostFooterProps = {
  author: Author;
};

const BlogPostFooter = ({ author }: BlogPostFooterProps) => (
  <footer>
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <div className="blog-post__meta">
            <Link to={`/author/${kebabCase(author.name)}`}>
              <AuthorImage author={author} />
            </Link>

            <div className="blog-post__meta--right">
              <div className="d-block">Written by {author.name}</div>
              <div>{author.bio}</div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default BlogPostFooter;
