import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BlogPost } from '../../types';
import BlogPostGrid from '../BlogPostGrid';

type BlogPostRelatedPostsProps = {
  posts: BlogPost[];
};

const BlogPostRelatedPosts = ({ posts }: BlogPostRelatedPostsProps) => (
  <Container className="mt-4">
    <Row className="justify-content-md-center">
      <Col md={10}>
        <h2>Related posts</h2>
      </Col>
    </Row>
    <BlogPostGrid posts={posts} />
  </Container>
);

export default BlogPostRelatedPosts;
